import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import {
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import {
	arrowsAxesIcon,
	chevronDownIcon,
	chevronUpIcon,
	fileIcon,
	pencilIcon,
	plusIcon,
	printIcon,
	trashIcon,
} from "@progress/kendo-svg-icons";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { DetailsCard } from "./DetailsCard";
import { DetailsDisplayLocation } from "./DetailsDisplayLocation";
import { DetailsInformationCard } from "./DetailsInformationCard";
import type { DetailsResponseGood } from "./api/JobApi";
import { DFlex } from "./display/DFlex";
import { DGrid } from "./display/DGrid";
import { type LegStatusType, legStatusTypeNames, toCurrency } from "./helpers";
import { DTag, DetailsDisplay } from "./helpersReact";

type DetailsGoodsProps = {
	data: {
		goods: DetailsResponseGood[];
		currencyCode: string | null;
	};
	onAddGoodClicked?: () => void;
	onSplitGoodsClicked?: () => void;
	onEditGoodClicked?: (id: number) => void;
	onRemoveGoodClicked: (id: number) => void;
	onGenerateCMRClicked: (id: number) => void;
	onGenerateCargoLabelClicked: (id: number) => void;
	getLegStatusColor: (status: number) => string;
	onViewDocumentsClicked: (id: number) => void;
	onAttachPODClicked: (id: number) => void;
};
export const DetailsGoods = ({
	data,
	onAddGoodClicked, // for load
	onSplitGoodsClicked, // for job
	onEditGoodClicked,
	onRemoveGoodClicked,
	onGenerateCMRClicked,
	onGenerateCargoLabelClicked,
	getLegStatusColor,
	onViewDocumentsClicked,
	onAttachPODClicked,
}: DetailsGoodsProps) => {
	const [expanded, setExpanded] = useState<number>();
	const totalRevenue = useMemo(
		() => data.goods.reduce((acc, x) => acc + x.revenue, 0),
		[data.goods],
	);
	return (
		<DetailsCard
			title={`Goods (${data.goods.length}) - ${toCurrency(
				totalRevenue,
				data.currencyCode,
			)}`}
			buttons={
				<>
					{onAddGoodClicked && (
						<Button
							svgIcon={plusIcon}
							themeColor="primary"
							onClick={onAddGoodClicked}
						>
							Add Good
						</Button>
					)}
					{onSplitGoodsClicked && (
						<Button
							svgIcon={arrowsAxesIcon}
							themeColor="primary"
							onClick={onSplitGoodsClicked}
						>
							Split Goods
						</Button>
					)}
				</>
			}
		>
			{data.goods.map((x) => {
				const hasLocation = x.collectionLocation && x.deliveryLocation;
				const podDocuments = x.documents.filter((x) => x.isPod);
				const nonPodDocuments = x.documents.filter((x) => !x.isPod);
				return (
					<div key={x.id}>
						<ExpansionPanel
							title={
								<DFlex spaceBetween vCenter>
									<DFlex vCenter>
										<DFlex column noGap>
											<DFlex vCenter>
												<b>{x.name}</b>
												{x.jobUniqueId && (
													<DTag onClick={(e) => e.stopPropagation()}>
														<Link to={`/jobs2/${x.jobId}`}>
															Job: {x.jobUniqueId}
														</Link>
													</DTag>
												)}
												{x.loads.map((x) => (
													<DTag key={x.id} onClick={(e) => e.stopPropagation()}>
														<Link to={`/loads2/${x.id}`}>
															Load: {x.uniqueId}
														</Link>
													</DTag>
												))}
											</DFlex>
											<div>
												{hasLocation && (
													<>
														{x.collectionLocation.name} {">>"}{" "}
														{x.deliveryLocation.name}
													</>
												)}
											</div>
										</DFlex>
									</DFlex>
									<DFlex gap="8px" vCenter>
										{x.clearCustomsStatuses.map((x) => (
											<DTag key={x} color={getLegStatusColor(x)}>
												{legStatusTypeNames[x as LegStatusType]}
											</DTag>
										))}
										<DTag>{toCurrency(x.revenue, x.currencyCode)}</DTag>
										{onEditGoodClicked && (
											<Button
												svgIcon={pencilIcon}
												onClick={(e) => {
													e.stopPropagation();
													onEditGoodClicked(x.id);
												}}
											/>
										)}
										<Button
											svgIcon={trashIcon}
											onClick={(e) => {
												e.stopPropagation();
												onRemoveGoodClicked(x.id);
											}}
										/>
									</DFlex>
								</DFlex>
							}
							expanded={expanded === x.id}
							onAction={() => setExpanded(x.id === expanded ? undefined : x.id)}
						>
							<Reveal>
								{expanded === x.id && (
									<ExpansionPanelContent>
										<DGrid columns="repeat(2, 1fr)" gap="8px">
											<DetailsInformationCard
												icon={chevronUpIcon}
												title="Start Location"
												value={
													<DetailsDisplayLocation {...x.collectionLocation} />
												}
											/>
											<DetailsInformationCard
												icon={chevronDownIcon}
												title="End Location"
												value={
													<DetailsDisplayLocation {...x.deliveryLocation} />
												}
											/>
										</DGrid>
										<DetailsDisplay label="Goods ID" value={x.uniqueId} />
										<DetailsDisplay
											label="Special Requirements"
											value={x.constraints?.map((c) => c.name).join(", ")}
										/>
										<DetailsDisplay
											label="Revenue"
											value={toCurrency(x.revenue, data.currencyCode)}
										/>
										<DetailsDisplay
											label="Quantity"
											value={x.quantity?.toFixed()}
										/>
										<DetailsDisplay
											label="Weight"
											value={x.weight ? `${x.weight.toFixed(2)} kg` : null}
										/>
										<DetailsDisplay label="Notes" value={x.notes} />
										<DFlex flexEnd>
											<Button
												svgIcon={fileIcon}
												onClick={() => onViewDocumentsClicked(x.id)}
											>
												Documents{" "}
												{nonPodDocuments.length
													? `(${nonPodDocuments.length})`
													: undefined}
											</Button>
											<Button
												svgIcon={fileIcon}
												onClick={() => onAttachPODClicked(x.id)}
											>
												POD{" "}
												{podDocuments.length
													? `(${podDocuments.length})`
													: undefined}
											</Button>
											<Button
												svgIcon={printIcon}
												themeColor="primary"
												onClick={() => onGenerateCMRClicked(x.id)}
											>
												Generate CMR
											</Button>
											<Button
												svgIcon={printIcon}
												themeColor="primary"
												onClick={() => onGenerateCargoLabelClicked(x.id)}
											>
												Generate Cargo Label
											</Button>
										</DFlex>
									</ExpansionPanelContent>
								)}
							</Reveal>
						</ExpansionPanel>
					</div>
				);
			})}
		</DetailsCard>
	);
};
