import { Button } from "@progress/kendo-react-buttons";
import { checkIcon } from "@progress/kendo-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type { GoodGridDataResponse } from "../api/JobApi";
import { GenericDataGrid } from "../grids/GenericDataGrid";
import {
	type TypedGridColumnProps,
	jobApi,
	jobStatusNamesAndColors,
	uncheckGridSelection,
} from "../helpers";
import { useDialog, useGenericDateRangePicker } from "../helpersReact";

type GoodGridData = GoodGridDataResponse & { jobStatusName: string };
const defaultColumns: TypedGridColumnProps<GoodGridData>[] = [
	{ field: "uniqueId", title: "Unique ID" },
	{ field: "jobTypeName", title: "Job Type" },
	{ field: "customerName", title: "Customer" },
	{ field: "constraintNames", title: "Constraints" },
	{ field: "name", title: "Name" },
	{ field: "description", title: "Description" },
	{ field: "quantity", title: "Quantity" },
	{ field: "weight", title: "Weight" },
	{ field: "collectionLocationName", title: "Collection Location" },
	{ field: "collectionDate", title: "Collection Date" },
	{ field: "deliveryLocationName", title: "Delivery Location" },
	{ field: "deliveryDate", title: "Delivery Date" },
	{ field: "jobStatusName", title: "Job Status" },
];

type GoodsGridWithDTOProps = {
	onGoodsSelected?: (goodIds: number[]) => unknown;
};
const GoodsGridWithDTO = ({ onGoodsSelected }: GoodsGridWithDTOProps) => {
	const [selectedGoods, setSelectedGoods] = useState<Set<number>>();
	const [loading, setLoading] = useState(false);
	const [dateRangeEl, rangeValues] =
		useGenericDateRangePicker("GoodsDateRange");

	const data = useQuery({
		queryKey: ["goods"],
		queryFn: () => jobApi.bff.bffGoodGridDataList().then((x) => x.data),
		initialData: [],
	});
	useEffect(() => {
		// ugly hack to uncheck the grid selection when the data is fetched after mount
		if (data.isFetchedAfterMount) {
			setLoading(false);
			uncheckGridSelection();
		}
	}, [data.isFetchedAfterMount]);

	const filteredData = data.data.filter((item) => {
		const collectionDate = new Date(item.collectionDate);
		return (
			(!rangeValues.start || collectionDate >= rangeValues.start) &&
			(!rangeValues.end || collectionDate <= rangeValues.end)
		);
	});

	if (!data.isFetchedAfterMount) return "Loading...";
	return (
		<GenericDataGrid
			defaultColumns={defaultColumns}
			extraFilters={dateRangeEl}
			name="Goods"
			data={filteredData.map((item) => ({
				...item,
				jobStatusName: jobStatusNamesAndColors[item.jobStatus]?.name ?? "",
			}))}
			refresh={data.refetch}
			loading={data.isFetching}
			onSelectionChange={setSelectedGoods}
			extraButtons={
				<Button
					themeColor="primary"
					svgIcon={checkIcon}
					onClick={async () => {
						setLoading(true);
						await onGoodsSelected?.([...(selectedGoods ?? [])]);
						setLoading(false);
					}}
					disabled={selectedGoods?.size === 0 || loading}
				>
					Add to Load ({selectedGoods?.size ?? 0} @{" "}
					{[...(selectedGoods ?? [])].reduce(
						(total, id) =>
							total + (data.data.find((item) => item.id === id)?.weight ?? 0),
						0,
					) ?? 0}{" "}
					kg)
				</Button>
			}
		/>
	);
};

export const useGoodsSelectionDialog = () => {
	const [props, setProps] = useState<GoodsGridWithDTOProps>();
	const [_toggleDialog, dialog] = useDialog(
		props && <GoodsGridWithDTO {...props} />,
		"Goods Selection",
	);

	const toggleDialog = (props?: GoodsGridWithDTOProps) => {
		setProps(props);
		_toggleDialog(props != null);
	};

	return [toggleDialog, dialog] as const;
};
