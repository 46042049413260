import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import {
	DocumentUsage,
	jobApi,
	toasted,
	useLookupConstraints,
	useLookupCustomers,
	useLookupJobTypes,
	useLookupLocations,
	useLookupUsers,
} from "../helpers";
import { JobForm, type JobFormProps } from "./JobForm";

type JobFormObject = Parameters<JobFormProps["onSubmit"]>[0];

export type JobFormWithDTOProps = {
	id?: number;
	isDuplicate?: boolean;
	showLocationForm?: (callback: (value: number) => void) => void;
	onSubmit?: (data: JobFormObject) => void;
};
export const JobFormWithDTO = ({
	id,
	isDuplicate,
	showLocationForm,
	onSubmit,
}: JobFormWithDTOProps) => {
	const _job = useQuery({
		queryKey: ["jobApi.job.getJob2", id],
		queryFn: async () => {
			if (!id) return null;
			const x = await jobApi.job.getJob2(id);
			return x.data;
		},
		refetchOnWindowFocus: false,
	});
	const lookupCustomers = useLookupCustomers();
	const lookupUsers = useLookupUsers();
	const [locationCreated, setLocationCreated] = useState(0);
	const lookupLocations = useLookupLocations(
		[_job.data?.startLocation?.id, _job.data?.endLocation?.id],
		[locationCreated],
	);
	const lookupConstraints = useLookupConstraints();
	const lookupJobTypes = useLookupJobTypes();

	const handleJobFormSubmit = useCallback(
		async (data: JobFormObject) => {
			const saveData = async (data: JobFormObject) => {
				const endDate = data.endDate
					? dayjs(data.endDate).format("YYYY-MM-DDTHH:mm:ss")
					: undefined;
				const startDate = data.startDate
					? dayjs(data.startDate).format("YYYY-MM-DDTHH:mm:ss")
					: undefined;

				if (!endDate || !startDate) throw new Error("Invalid dates");

				if (id && !isDuplicate) {
					await jobApi.job.putJob({
						...data,
						id: id,
						endDate,
						startDate,
					});
					await jobApi.attachment.attachmentCreate(
						{
							entityId: id,
							usage: DocumentUsage.Job,
							isPod: false,
						},
						{ files: data.documents },
					);
					return id;
				}

				const response = await jobApi.job.postJob({
					...data,
					endDate,
					startDate,
				});

				const jobId = response.data.id;

				await jobApi.attachment.attachmentCreate(
					{
						entityId: jobId,
						usage: DocumentUsage.Job,
						isPod: false,
					},
					{ files: data.documents },
				);
				return jobId;
			};
			const jobId = await toasted(
				saveData(data),
				data.id ? "Updating Job" : "Creating Job",
			);
			onSubmit?.({
				...data,
				id: jobId,
			});
		},
		[onSubmit, id, isDuplicate],
	);

	const defaultValues: Partial<JobFormObject> = useMemo(() => {
		if (!_job.data) {
			return {
				startDate: dayjs().startOf("d").add(8, "h").toDate(),
				endDate: dayjs().startOf("d").add(16, "h").toDate(),
				purchaseOrderNumber: "TBC",
				assignedTo: JSON.parse(
					JSON.parse(localStorage["persist:authentication"] ?? "{}").userId ??
						'""',
				),
				goodsInfo: {
					name: "TBC",
					quantity: 1,
					weight: 1,
				},
				useVat: true,
			};
		}
		return {
			startDate: new Date(_job.data.startDate),
			endDate: new Date(_job.data.endDate),
			purchaseOrderNumber: _job.data.purchaseOrderNumber ?? undefined,
			assignedTo: _job.data.assignedTo ?? undefined,
			containerNumber: _job.data.containerNumber ?? undefined,
			containerPin: _job.data.containerPin ?? undefined,
			containerSealNumber: _job.data.containerSealNumber ?? undefined,
			customerId: _job.data.customer?.id ?? undefined,
			endLocationId: _job.data.endLocation?.id ?? undefined,
			startLocationId: _job.data.startLocation?.id ?? undefined,
			id: isDuplicate ? undefined : _job.data.id,
			jobTypeId: _job.data.jobType?.id ?? undefined,
			notes: _job.data.notes ?? undefined,
			price: _job.data.price ?? undefined,
			useVat: _job.data.useVat ?? undefined,
			goodsInfo: {
				name: "TBC",
				quantity: 1,
				weight: 1,
			},
		};
	}, [_job.data, isDuplicate]);

	if (!_job.isFetchedAfterMount) return <div>Loading...</div>;

	return (
		<JobForm
			lUsers={lookupUsers}
			lConstraints={lookupConstraints}
			lCustomers={lookupCustomers}
			lLocations={lookupLocations}
			lJobTypes={lookupJobTypes}
			onSubmit={handleJobFormSubmit}
			defaultValues={defaultValues}
			onAddLocation={
				showLocationForm
					? (x) => {
							showLocationForm((id) => {
								x(id);
								setLocationCreated((x) => x + 1);
							});
						}
					: undefined
			}
		/>
	);
};
