import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import type { LoadFormObject } from "../Load";
import {
	DocumentUsage,
	jobApi,
	toasted,
	useLookupLoadTypes,
	useLookupLocations,
	useLookupTrailers,
	useLookupUsers,
} from "../helpers";
import { LoadForm } from "./LoadForm";

export type LoadFormWithDTOProps = {
	id?: number;
	isDuplicate?: boolean;
	showLocationForm?: (callback: (value: number) => void) => void;
	onSubmit?: (data: LoadFormObject) => void;
};
export const LoadFormWithDTO = ({
	id,
	isDuplicate,
	showLocationForm,
	onSubmit,
}: LoadFormWithDTOProps) => {
	const _load = useQuery({
		queryKey: ["jobApi.load.loadDetail", id],
		queryFn: () => {
			if (!id) return Promise.resolve(undefined);
			return jobApi.load.loadDetail(id).then((x) => x.data);
		},
	});
	const lookupLoadTypes = useLookupLoadTypes();
	const [locationCreated, setLocationCreated] = useState(0);
	const lookupUsers = useLookupUsers();
	const lookupLocations = useLookupLocations(
		[_load.data?.startLocation?.id, _load.data?.endLocation?.id],
		[locationCreated],
	);
	const lookupTrailers = useLookupTrailers();
	const handleLoadFormSubmit = useCallback(
		async (data: LoadFormObject) => {
			const saveOrUpdate = async (data: LoadFormObject) => {
				const endDate = data.endDate
					? dayjs(data.endDate).format("YYYY-MM-DDTHH:mm:ss")
					: undefined;
				const startDate = dayjs(data.startDate).format("YYYY-MM-DDTHH:mm:ss");
				if (id && !isDuplicate) {
					await jobApi.load.loadUpdate({
						...data,
						id: id,
						endDate,
						startDate,
					});
					await jobApi.attachment.attachmentCreate(
						{
							entityId: id,
							usage: DocumentUsage.Load,
							isPod: false,
						},
						{ files: data.documents },
					);
					return;
				}
				const response = await jobApi.load.loadCreate({
					...data,
					endDate,
					startDate,
				});
				await jobApi.attachment.attachmentCreate(
					{
						entityId: response.data.id,
						usage: DocumentUsage.Load,
						isPod: false,
					},
					{ files: data.documents },
				);
				return response;
			};
			const response = await toasted(
				saveOrUpdate(data),
				data.id ? "Updating load" : "Creating load",
			);
			onSubmit?.({
				...data,
				id: response?.data.id,
			});
		},
		[id, isDuplicate, onSubmit],
	);
	const defaultValues: Partial<LoadFormObject> = useMemo(() => {
		if (!_load.data) {
			return {
				startDate: dayjs().startOf("d").add(8, "h").toDate(),
			};
		}

		return {
			startDate: new Date(_load.data?.startDate ?? ""),
			assignedTo: _load.data?.assignedTo ?? undefined,
			endDate: _load.data?.endDate ? new Date(_load.data?.endDate) : undefined,
			endLocationId: _load.data?.endLocation?.id,
			startLocationId: _load.data?.startLocation?.id,
			id: isDuplicate ? undefined : _load.data?.id,
			loadTypeId: _load.data?.loadType?.id,
			notes: _load.data?.notes ?? undefined,
			trailerId: _load.data?.trailer?.id,
		} satisfies Partial<LoadFormObject>;
	}, [_load.data, isDuplicate]);

	if (!_load.isFetchedAfterMount) return <div>Loading...</div>;

	return (
		<LoadForm
			defaultValues={defaultValues}
			onSubmit={handleLoadFormSubmit}
			lLoadTypes={lookupLoadTypes}
			lLocations={lookupLocations}
			lUsers={lookupUsers}
			lTrailers={lookupTrailers}
			onAddLocation={
				showLocationForm
					? (x) => {
							showLocationForm((id) => {
								x(id);
								setLocationCreated((x) => x + 1);
							});
						}
					: undefined
			}
		/>
	);
};
